import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SideNavV2 from "../../components/SideNav/SideNavV2";

import { DataContext } from "../../context/Data";
import NoRouteV2 from "../../components/StatusPages/NoRouteV2";
import { AstratoContext } from "../../context/Astrato";
import { AuthContext } from "../../context/Authorization";
import getIframeLinkByNavlink from "../../utils/getIframeLinkByNavlink";
import { LoggingContext } from "../../context/Logger";

export default function DTC_V2() {
  const { AllowedTo, useAuth } = React.useContext(AuthContext);
  const { userHasPermission } = useAuth();
  const { v2Routes, account, loadingChangeSpace } = useContext(DataContext);
  const { iframeSrc, isAuthenticated, iframeRef } = useContext(AstratoContext);
  const { log } = React.useContext(LoggingContext);

  const stringStaging = window.location.origin.includes("staging") ? "_staging" : "";

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.src !== iframeSrc) {
      iframeRef.current.src = iframeSrc;
    }
  }, [iframeSrc]);

  function getIframeLink(navlink) {
    let result = getIframeLinkByNavlink(v2Routes[`dtc_routes${stringStaging}`], navlink);
    if (result) {
      return { ...result, navlink: "" };
    }
  }

  if (!userHasPermission(["ROUTE_DTC_V2_USER"], account)) {
    return <NoRouteV2 />;
  }

  return (
    <div className="flex flex-grow overflow-hidden h-[calc(100vh-63px)]">
      <SideNavV2 route="dtc" />
      <main className="p-2 h-full w-full">
        <div className="flex flex-col h-full w-full">
          <Routes>
            {v2Routes.hasOwnProperty(`dtc_routes${stringStaging}`) &&
              [
                {
                  links: [getIframeLink("kpi_dashboard")],
                  permission: "DTC_NAV_KPI_DASHBOARD",
                },
                ...v2Routes[`dtc_routes${stringStaging}`],
              ]?.map((r) => {
                return r.links.map((l) => {
                  return (
                    <Route
                      key={l.navlink}
                      path={l.navlink}
                      element={
                        <div className="w-full h-full">
                          <AllowedTo perform={r.permission} no={() => <NoRouteV2 />} data={account}>
                            {l.iframeLink ? (
                              <div className="w-full h-full">
                                {!isAuthenticated || loadingChangeSpace ? (
                                  <div className="w-full h-full flex justify-center items-center">
                                    <svg
                                      className="animate-spin h-5 w-5 mr-3 text-main"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                      ></path>
                                    </svg>
                                  </div>
                                ) : (
                                  <>
                                    <iframe
                                      ref={iframeRef}
                                      className="w-full h-full"
                                      title="iframeAstratoDTC"
                                      src={iframeSrc || l.iframeLink}
                                      onLoad={(e) => {
                                        log.debug("Iframe DTC loaded with new src!", e);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <div>{l.label}</div>
                            )}
                          </AllowedTo>
                        </div>
                      }
                    />
                  );
                });
              })}
          </Routes>
        </div>
      </main>
    </div>
  );
}
