import { createContext, useContext, useEffect, useRef, useState } from "react";
import { FirebaseContext } from "./Firebase";

import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "./Data";
import getIframeLinkByNavlink from "../utils/getIframeLinkByNavlink";

import { AstratoConfig } from "./Config";
import { LoggingContext } from "./Logger";
import { AuthContext } from "./Authorization";
const ASTRATO_URL = AstratoConfig.baseURL;
const APP_NAME = AstratoConfig.appName;

const AstratoContext = createContext();

const AstratoProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { v2Routes, space, account, callConfigAstratoConnection } = useContext(DataContext);
  const { log } = useContext(LoggingContext);
  const { functions, db } = useContext(FirebaseContext);
  const { authData } = useContext(AuthContext);
  const [ticket, setTicket] = useState();
  const [iframeSrc, setIframeSrc] = useState();
  const [itemSelected, setItemSelected] = useState();
  const [v2RoutesSelected, setV2RoutesSelected] = useState();
  const iframeRef = useRef(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const itemSelectedRef = useRef(itemSelected);

  const v2RoutesSelectedRef = useRef(v2RoutesSelected);

  const accountChangedRef = useRef(account);

  const stringStaging = window.location.origin.includes("staging") ? "_staging" : "";

  useEffect(() => {
    itemSelectedRef.current = itemSelected;
  }, [itemSelected]);

  useEffect(() => {
    v2RoutesSelectedRef.current = v2RoutesSelected;
  }, [v2RoutesSelected]);

  useEffect(() => {
    accountChangedRef.current = account;
  }, [account]);

  const refreshUser = async () => {
    const { data } = await functions.refreshUserSession();
    setTicket(data.ticket);
    return data.ticket;
  };

  useEffect(() => {
    callConfigAstratoConnection(APP_NAME[space], account, space);
  }, []);

  useEffect(() => {
    const currentRoute = v2Routes[
      location.pathname.includes("v2/dtc") ? `dtc_routes${stringStaging}` : `depl_routes${stringStaging}`
    ]?.find((r) => r.links.some((l) => location.pathname.includes(l.navlink)));

    setV2RoutesSelected(
      v2Routes[location.pathname.includes("v2/dtc") ? `dtc_routes${stringStaging}` : `depl_routes${stringStaging}`]
    );

    if (currentRoute) {
      const currentLink = currentRoute.links.find((l) => location.pathname.includes(l.navlink));
      if (iframeSrc !== (currentLink?.iframeLink || null)) {
        setIframeSrc(currentLink?.iframeLink || null);
      }
      if (itemSelected?.label !== currentLink.label) {
        setItemSelected(currentLink);
      }
    } else if (location.pathname.includes("v2/dtc")) {
      if (v2Routes.hasOwnProperty(`dtc_routes${stringStaging}`)) {
        const result = getIframeLinkByNavlink(v2Routes[`dtc_routes${stringStaging}`], "kpi_dashboard");
        if (iframeSrc !== (result?.iframeLink || null)) {
          setIframeSrc(result?.iframeLink || null);
        }
        if (itemSelected?.label !== result.label) {
          setItemSelected(result);
        }
      }
    } else if (location.pathname.includes("v2/depl")) {
      if (v2Routes.hasOwnProperty(`depl_routes${stringStaging}`)) {
        const result = getIframeLinkByNavlink(v2Routes[`depl_routes${stringStaging}`], "kpi_dashboard");
        if (iframeSrc !== (result?.iframeLink || null)) {
          setIframeSrc(result?.iframeLink || null);
        }
        if (itemSelected?.label !== result.label) {
          setItemSelected(result);
        }
      }
    }
  }, [location, v2Routes, iframeSrc, itemSelected]);

  useEffect(() => {
    const handleMessage = (event) => {
      log.debug("# ENO # - New message from Iframe -", event.data);

      handleIncomingMessage(event.data);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleIncomingMessage = async (data) => {
    switch (data.type) {
      /**
       * "Astrato" type is the default to refresh cookie
       */
      case "Astrato":
        if (data.payload === "401Error") {
          log.debug("# ENO # - Running 401Error function");
          await generateNewToken(data);
        }
        break;

      /**
       * Message from Astrato to send him the Sheet
       *
       * ASTRATO_NAVIGATE_GET_SHEET - Sent from Astrato before workbook opens
       */
      case "ASTRATO_NAVIGATE_GET_SHEET":
        const messageToIframeConverted = JSON.parse(
          itemSelectedRef.current.messageToIframe.replace(/(\w+):/g, '"$1":')
        );
        log.debug("# ENO # - ASTRATO_NAVIGATE_GET_SHEET", messageToIframeConverted);
        sendMessageToIframe(messageToIframeConverted);
        createNewEntryLog({ ...data, type: "ASTRATO_OPEN_WORKBOOK" });
        break;

      /**
       * Message from Astrato to send him the Sheet
       *
       * ASTRATO_OPEN_SHEET - Sent from Astrato when there is a click button, passing the new Active Sheet
       */
      case "ASTRATO_OPEN_SHEET":
        log.debug("# ENO # - ASTRATO_OPEN_SHEET");

        getLabelByPayload(data.payload);
        createNewEntryLog(data);
        break;

      default:
        log.debug("# ENO # - Type is not exists.");
        break;
    }
  };

  const generateNewToken = async (data) => {
    if (data?.type === "Astrato" && data?.message === "401Error") {
      setIsAuthenticated(false);

      const res = await refreshUser();
      log.debug("# ENO # - TOKEN GENERATED", res);

      if (res !== null) {
        log.debug("# ENO # - TOKEN IN FETCH", `${ASTRATO_URL}auth/proxy/oem/ticket/${res}?embed`);
        try {
          const response = await fetch(`${ASTRATO_URL}auth/proxy/oem/ticket/${res}?embed`, {
            credentials: "include",
          });
          log.debug("# ENO # - FETCH RESPONSE", response);
          const jsonResponse = await response.json();
          log.debug("# ENO # - Authenticated");
          setIframeSrc((prev) => `${prev.split("?")[0]}`);
          setIsAuthenticated(true);
          return jsonResponse;
        } catch (error) {
          console.error("# ENO # - Error during ticket fetch:", error);
          return null;
        }
      }
    }
  };

  const getLabelByPayload = (payload) => {
    log.debug("(v2RoutesSelectedRef)", v2RoutesSelectedRef);

    for (const section of v2RoutesSelectedRef.current) {
      if (section.links) {
        for (const link of section.links) {
          if (link.messageToIframe) {
            try {
              const messageObj = JSON.parse(link.messageToIframe.replace(/(\w+)\s*:/g, '"$1":'));
              if (messageObj.payload === payload) {
                log.debug("GOING TO", space, link.navlink);
                navigate(`${space}/${link.navlink}`);
              }
            } catch (error) {
              console.warn("Invalid JSON format in messageToIframe:", link.messageToIframe);
            }
          }
        }
      }
    }

    return;
  };

  const createNewEntryLog = async (data) => {
    const obj = {
      accountEID: accountChangedRef.current,
      accountName: authData.accounts[accountChangedRef.current].account_name,
      emailUser: authData.user.email,
      event_payload: data.payload,
      event_type: data.type,
      space: space,
      timestamp: new Date().toLocaleString(),
      timestampISO: new Date().toISOString(),
      userId: authData.user.id,
      userName: authData.user.name,
    };

    try {
      const res = await db.collection("astrato_logs").add(obj);
      log.debug("SAVED", res.id);
    } catch (error) {
      log.debug("ERRO SAVE LOG", error);
    }
  };

  const sendMessageToIframe = (message) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(message, "*");
    }
  };

  return (
    <AstratoContext.Provider
      value={{ ticket, refreshUser, iframeSrc, isAuthenticated, iframeRef, sendMessageToIframe }}
    >
      {children}
    </AstratoContext.Provider>
  );
};

export { AstratoProvider, AstratoContext };
